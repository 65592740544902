import { Controller } from "stimulus"
import { csrfToken } from './mixins/csrf_token'

export default class extends Controller {
  static targets = ['exportButton', 'exportAll']
  static values = {
    eventType: String
  }

  connect() {
    csrfToken(this)
  }

  exportLease(event) {
    event.target.classList += " disabled"

    fetch(event.target.dataset['formAction'], {
      method: "POST",
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "X-CSRF-Token": this.csrfToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ event_type: this.eventTypeValue })
    })
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  exportAllLeases(event) {
    this.exportButtonTargets.forEach(button => {
      button.click()
    })
  }
}
