import { Controller } from 'stimulus'
import Dropzone from 'dropzone'

Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = ['readUnreadIcon', 'fileIframe', 'hiddenComment', 'hiddenComplianceComment', 'hiddenExpiresAt', 'hiddenDocumentNumber', 'hiddenDateOfBirth', 'submitButton', 'modal', 'modalFooter']
  static values = {
    shouldOpen: Boolean
  }

  connect() {
    // Backdrop needs to be removed when modal stays closed, but also for re-opening as it will create another one
    $('.modal-backdrop').remove()
    if (document.body.classList.contains('modal-open')) {
      if (this.shouldOpenValue === true) {
        $(this.modalTarget).modal('show')
        this.modalFooterTarget.scrollIntoView(true)
      } else {
        document.body.classList.remove('modal-open')
      }
    }

    $(this.element).find('[data-toggle="tooltip"]').tooltip()
  }

  disableButtons(event) {
    this.submitButtonTargets.forEach((button) => {
      if (button !== event.currentTarget) {
        button.disabled = true
      }
    })
  }

  markAsRead(_event) {
    if (this.hasReadUnreadIconTarget) {
      this.readUnreadIconTarget.classList.add('fa-envelope-open')
      this.readUnreadIconTarget.classList.remove('fa-envelope')
      this.readUnreadIconTarget.classList.remove('text-info')
    }
  }

  // Assign the iframe src lazy, so that not all files
  // are retrieved when opening the attachments page
  loadFileIframe(_event) {
    const fileIframes = this.fileIframeTargets

    fileIframes.forEach((fileIframe) => {
      fileIframe.src = fileIframe.dataset.fileUrl
    })
  }

  updateHiddenDocumentNumber(input) {
    this.updateTargets(this.hiddenDocumentNumberTargets, input)
  }

  updateHiddenDateOfBirth(input) {
    this.updateTargets(this.hiddenDateOfBirthTargets, input)
  }

  updateHiddenComments(input) {
    this.updateTargets(this.hiddenCommentTargets, input)
  }

  updateHiddenComplianceComment(input) {
    this.updateTargets(this.hiddenComplianceCommentTargets, input)
  }

  updateHiddenExpiresAt(input) {
    this.updateTargets(this.hiddenExpiresAtTargets, input)
  }

  updateTargets(targets, input) {
    targets.forEach((target) => {
      target.value = input.target.value
    })
  }
}
